import React, { useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './DashboardReffals.css';
import Tooltip from '@mui/material/Tooltip';
// npm install @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import { USDStakeContext } from '../../../../Contexts/USDContext';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Loader from '../../../../Component/Loader/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles({

    container: {
        backgroundColor: '#E6EDFA !important',
        width: "670px !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
        paddingTop: "12px",
        paddingBottom: "15px",
        '@media (min-width: 300px)': {
            // adjust the width at 600px and above
            width: '100% !important',
        },
        '@media (min-width: 960px)': {
            // adjust the width at 960px and above
            width: "670px !important",
        },
    },
});


function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const rows = [
    createData('D6XXXXXXXXXXXXr43w34', 100, "03/05/23", 4),
    createData('D6XXXXXXXXXXXXr43w34', 50, "03/05/23", 7),
    createData('D6XXXXXXXXXXXXr43w34', 150, "03/05/23", 4),
    createData('D6XXXXXXXXXXXXr43w34', 200, "03/05/23", 7),
];

const DashboardReferrals = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { user, getBalanceTestnet } = useContext(USDStakeContext);
    const [refData, setRefData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetching = async () => {
            setLoading(true);
            await axios.get("https://api.utistaking.com/api/v1/stake/all")
                .then(res => {
                    if (res.status === 200) {
                        let filtering = res.data?.data?.filter(item => item?.referredBy == user?.myReferralCode.split('com/')[1]);
                        setRefData(() => filtering);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        fetching();
        getBalanceTestnet();
    }, [user?.myReferralCode])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const copyAffiliateAddress = () => {
        // navigator.clipboard.writeText(user?.myReferralCode);
        if (user?.myReferralCode) {
            toast.success("Copied");
        }
        else {
            toast.error("Please login before copy")
        }
    }
    // console.log(refData.length, refData);

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            timeZone: 'Asia/Kolkata',
        };
        const date = new Date(dateString);
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        // const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    return (
        <section className="bg-referral">

            <div className='container mx-auto text-center fw-bold text-dark' style={{ paddingTop: "px" }}>
                <div className='mx-auto d-flex justify-content-center align-items-center flex-column mt-5 mb-4' style={{ gap: "15px" }}>
                    <p className='fs-5 mb-0'>Refer  To Get Instant 10% Of Your Referal Deposit To Your Wallet</p>
                    <div className='titleRound rounded-pill px-4' style={{ backgroundColor: "#D7EEFF" }}>
                        <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title="Stake Minimum UNITIC 5000 To Activate Referral Link">
                            <button className='bg-none border-0 fs-5' style={{ background: "transparent" }}><i class="fas fa-info-circle"></i></button>
                        </Tooltip>
                        {
                            (user?.transactionId === "T0") ?
                                <><p className='mb-0'>Affiliate Link  : Link not activated</p></> :
                                <>

                                    <p className='mb-0'>Affiliate Link  : {user?.myReferralCode.split('com/')[1]}</p>
                                    {/* <img src="/assets/copy.png" alt="" className='img-fluid ps-2' style={{ cursor: "pointer" }} onClick={() => copyAffiliateAddress()} /> */}

                                    <CopyToClipboard text={`${user?.myReferralCode}`} onCopy={copyAffiliateAddress}>
                                        <img src="/assets/copy.png" alt="" className='img-fluid ps-2' style={{ cursor: "pointer" }} />
                                    </CopyToClipboard>

                                </>
                        }

                    </div>
                </div>
                <div>
                    {
                        loading ? <Loader /> :
                            <>
                                {
                                    (refData.length <= 0) ? <img src="/assets/refferal.png" alt="" className='img-fluid' /> :


                                        <TableContainer component={Paper} className={classes.container}>
                                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Date</TableCell>
                                                        <TableCell align='center'>Address</TableCell>
                                                        <TableCell align="center">Deposit</TableCell>
                                                        <TableCell align="center" className='pe-3'>Earned (10%)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {refData.map((row) => (
                                                        <TableRow
                                                            key={row._id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell align="center">{formatDate(row?.createdAt)}</TableCell>
                                                            <TableCell align="center">{row?.walletAddress}</TableCell>
                                                            <TableCell align="center">{row?.investedAmount}</TableCell>
                                                            <TableCell align="center" className='pe-4'>{(parseFloat(row?.investedAmount)) / 10}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                }
                            </>
                    }
                </div>
            </div>
        </section>
    );
};

export default DashboardReferrals;