import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Header.css'
import { Link, useLocation } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { USDStakeContext } from '../../Contexts/USDContext';
import swal from 'sweetalert';

const Header = () => {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const { logOut, user, openWalletModal, openWalletModalWarning, anchorEl, setAnchorEl, connectToCoinbase, connectToWalletConnect, connectToTrustWallet } = useContext(USDStakeContext);
    const pathname = useLocation().pathname;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const callingMetamask = () => {
        openWalletModal();
        // setAnchorEl(null);
    };
    const LogOut = () => {
        logOut();
        swal({
            title: "Success",
            text: "You have successfully logged out.",
            icon: "success",
            button: "OK",
            className: "modal_class_success",
        });
    }
    return (
        <div>
            {['md'].map((expand) => (
                <Navbar key={expand} expand={expand} collapseOnSelect className="pb-0 p-3 text-dark border-0" style={{ background: (window.innerWidth < 770) ? "white" : "transparent", border: "0" }}>
                    <Container>
                        <Navbar.Brand href="#" as={Link} to={"/"} className='for-logo-width'>
                            <img src="./footerv3.png" alt="" className='logo-width img-fluid' width={200}/>
                            {/* <h3 className='fw-bold'>UTISTAKE</h3> */}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            className="w-75 text-dark"
                            style={{ background: (window.innerWidth < 770) ? "white" : "transparent" }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <img src="./footerv3.png" alt="" className='logo-width2 img-fluid' />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end align-items-center flex-grow-1 pe-sm-0 pe-lg-5 text-dark">
                                    <Nav.Link href="#action1" as={Link} to={"/home"} className={`text-dark px-sm-0 px-md-2 px-lg-4 ${(pathname.includes("/home")) ? "fw-bolder" : ""}`}>Home</Nav.Link>
                                    {
                                        (!user?.walletAddress || user?.walletAddress === undefined || user?.walletAddress === "undefined") ? <Nav.Link onClick={() => {
                                            localStorage.setItem('selectedTab', "0");
                                            openWalletModalWarning();
                                        }} className={`text-dark px-sm-0 px-md-2 px-lg-4 ${(pathname.includes("/dashboard")) ? "fw-bolder" : ""}`}>Dashboard</Nav.Link> :
                                            <Nav.Link href="#action2" as={Link} to={"/dashboard"} className={`text-dark px-sm-0 px-md-2 px-lg-4 ${(pathname.includes("/dashboard")) ? "fw-bolder" : ""}`} onClick={() => {
                                                localStorage.setItem('selectedTab', "0");
                                            }}>Dashboard</Nav.Link>
                                    }
                                    <Nav.Link href="#action3" className={`text-dark px-sm-0 px-md-2 px-lg-4 ${(pathname.includes("/affiliate")) ? "fw-bolder" : ""}`} as={Link} to="/affiliate">Affiliate</Nav.Link>

                                    <Nav.Link className='text-dark'>
                                        {
                                            (!user?.walletAddress || user?.walletAddress === undefined || user?.walletAddress === "undefined") ?
                                                <Button variant="success" id="fade-button"
                                                    aria-controls={open ? 'fade-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick} className='px-0 connectWallet'>Connect Wallet</Button> : <Button variant="success" id="fade-button"
                                                        aria-controls={open ? 'fade-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={() => LogOut()} className='px-0 connectWallet'>Log Out</Button>
                                        }
                                    </Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                className='mt-3 align-items-center'
            >
                <MenuItem onClick={callingMetamask}><img src="/assets/metamask.png" alt="" className='img-fluid pe-2' />Meta mask</MenuItem>
                <MenuItem onClick={() => { connectToTrustWallet(); handleClose(); }}><img src="/assets/trust.png" alt="" className='img-fluid pe-2' />Trust Wallet</MenuItem>
                {/* <MenuItem onClick={() => {
                    connectToWalletConnect();
                    handleClose();
                }}><img src="/assets/walletc.png" alt="" className='img-fluid pe-2' />Wallet Connect</MenuItem> */}
                <MenuItem onClick={() => connectToCoinbase()}><img src="/assets/walletl.png" alt="" className='img-fluid pe-2' style={{ marginLeft: "2px" }} width={45} />Coinbase</MenuItem>
            </Menu>
            <div className='container mx-auto text-center'>
                <hr style={{ color: "black", border: "1px solid black", background: "black" }} />
                {/* <img src="/assets/linev2.png" alt="" className='img-fluid' /> */}
            </div>
        </div>
    );
};

export default Header;