import React, { useContext, useEffect, useState } from 'react';
import './DashboardMyStakingDetails.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { USDStakeContext } from '../../../../Contexts/USDContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../Component/Loader/Loader';

const DashboardMyStakingDetails = () => {
    const [data, setData] = useState([]);
    const { user, withdrawUSDT, roi, prcntge, stakeListRefetch, setStakeListRefetch, getBalanceTestnet } = useContext(USDStakeContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [utcTime] = useState('2023-06-02T15:47:36.665Z');
    // const [localTime, setLocalTime] = useState('');
    // useEffect(() => {
    //     const utcDate = new Date(utcTime);
    //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     const options = {
    //         year: 'numeric',
    //         month: 'long',
    //         day: 'numeric',
    //         hour: 'numeric',
    //         minute: 'numeric',
    //         second: 'numeric',
    //         hour12: true,
    //         timeZone: userTimeZone
    //     };
    //     const localDate = utcDate.toLocaleString(undefined, options);
    //     setLocalTime(localDate);
    // }, [utcTime]);
    // console.log(localTime);

    // setInterval(async () => {
    //     setStakeListRefetch(!stakeListRefetch);
    // }, 60000);

    useEffect(() => {
        const fetching = async () => {
            setLoading(true);
            await axios.get(`https://api.utistaking.com/api/v1/stake/${user?.walletAddress}`, {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('userOfutistake')}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        setData(() => res.data?.data?.reverse());
                        // console.log(res.data?.data);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        fetching();
        getBalanceTestnet();
    }, [user?.walletAddress, stakeListRefetch]);
    // console.log(data);
    const formatDate = (dateString) => {
        const utcDate = new Date(dateString);
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
            timeZone: userTimeZone
        };
        const localDate = utcDate.toLocaleString(undefined, options);
        return localDate;
    };
    return (
        <div className='container mx-auto' style={{ paddingBottom: "50px" }}>
            {
                loading ? <Loader /> :
                    <>
                        {
                            (data.length > 0) ? <>

                                {

                                    data?.map(((item, index) =>
                                        <>
                                            <div className='manageTable' key={index}>
                                                <div className='gridding mx-auto text-center px-3'>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Date</p>
                                                        <p className='py-2 mb-0'>{formatDate(item?.createdAt)}</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Staked</p>
                                                        <p className='py-2 mb-0'>{item?.investedAmount}</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Daily ROI</p>
                                                        <p className='py-2 mb-0'>{roi}%</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Total Earned</p>
                                                        <p className='py-2 mb-0'>{(item?.totalEarned === 0) ? "0.00" : item?.totalEarned} UNITIC</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Remaining</p>
                                                        <p className='py-2 mb-0'>{((Number(item?.investedAmount) * 2) - item?.totalEarned)?.toFixed(2)} UNITIC</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Days Left</p>
                                                        <p className='py-2 mb-0'>{Math.round((((Number(item?.investedAmount) * 2) - (item?.totalEarned)) / (Number(item?.investedAmount) * (1 - prcntge))))} Days</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        <p className='py-2 mb-0 fw-bold'>Eligible Claim</p>
                                                        <p className='py-2 mb-0'>{item?.claimAmount} UNITIC</p>
                                                    </div>
                                                    <div class="itemGrid">
                                                        {
                                                            ((item?.status === false) && (item?.claimedAmount == item?.totalEarned)) ? <Button variant="danger" className='py-3 px-4' disabled>Closed</Button> :
                                                                <>
                                                                    {

                                                                        (item?.claimedAmount >= ((Number(item.investedAmount) * 2) * 0.90)) ?

                                                                            <Button
                                                                                variant="success" className='py-3 px-4' onClick={() => withdrawUSDT((data?.length - index), item?._id, item?.claimAmount)}
                                                                                // what is the reason for disabled?
                                                                                disabled={item?.totalEarned != (Number(item?.investedAmount) * 2)}

                                                                            >Claim</Button>

                                                                            :
                                                                            <Button
                                                                                variant="success" className='py-3 px-4' onClick={() => withdrawUSDT((data?.length - index), item?._id, item?.claimAmount)}
                                                                                // what is the reason for disabled?
                                                                                disabled={(item?.claimAmount < (Number(item?.investedAmount) * 0.10))}

                                                                            >Claim</Button>
                                                                    }
                                                                </>

                                                        }
                                                        {/* <Button variant="success" className='py-3 px-4'
                                                        disabled
                                                        onClick={() => withdrawUSDT((data?.length - index), item?._id, item?.claimAmount)}
                                                    >Claim</Button> */}
                                                    </div>

                                                </div>  </div>
                                            <p className='mb-0 pb-0 pt-2 text-end pe-5 fw-bold'>Claim will be enabled once reached 10% of stake</p>
                                        </>
                                    ))

                                }

                            </> :
                                <div className='mx-auto text-center'>
                                    <p style={{ fontWeight: "800" }}>You Have'nt Staked Yet. Click Stake Button To Stake UNITIC</p>
                                    <Button variant="success" className='ms-3 connectWallet' style={{ width: "115px" }} onClick={() => navigate(`/dashboard/stake`)}>Stake</Button>
                                    <br />
                                    <img src="https://i.ibb.co/pyYht5c/mystake.png" className='img-fluid mt-5' alt="pic" />
                                </div>
                        }

                    </>
            }

        </div>
    );
};

export default DashboardMyStakingDetails;