import React, { useContext, useState, useEffect } from 'react';
import './DashboardHome.css';
import { USDStakeContext } from '../../../../Contexts/USDContext';
import { Button } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const DashboardHome = () => {
    const { user, metamaskBalance, getBalanceTestnet, level, roi, fetchData, contractData } = useContext(USDStakeContext);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sumStake, setSumStake] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);
    const [claim, setClaim] = useState(0);
    // console.log("transactionId", user?.transactionId);
    useEffect(() => {
        const fetching = async () => {
            setLoading(true);
            await axios.get(`https://api.utistaking.com/api/v1/stake/${user?.walletAddress}`, {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('userOfutistake')}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        setData(() => res.data?.data?.reverse());
                        const totalInvestedAmount = res.data?.data.reduce((sum, item) => sum + parseFloat(item.investedAmount), 0);
                        const totalEarned = res.data?.data.reduce((sum, item) => sum + parseFloat(item.totalEarned), 0);
                        const totalClaim = res.data?.data.reduce((sum, item) => sum + parseFloat(item?.claimedAmount), 0);
                        // console.log(totalEarned, "t");
                        setSumStake(() => parseFloat(totalInvestedAmount)?.toFixed(2));
                        setTotalEarned(() => parseFloat(totalEarned)?.toFixed(2));
                        setClaim(() => parseFloat(totalClaim)?.toFixed(2));

                        // let summing = res.data?.data.filter(item => item.investedAmount)
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        fetching();
        // fetchData();
        getBalanceTestnet();
    }, [user?.walletAddress, fetchData]);
    // console.log("testing", contractData);
    useEffect(() => {
        getBalanceTestnet();
    }, []);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const copyWalletAddress = () => {
        // navigator.clipboard.writeText(user?.walletAddress);
        if (user?.walletAddress) {
            toast.success("Copied");
        }
        else {
            toast.error("Please login before copy")
        }
    }
    const copyAffiliateAddress = () => {
        // navigator.clipboard.writeText(user?.myReferralCode);
        if (user?.myReferralCode) {
            toast.success("Copied");
        }
        else {
            toast.error("Please login before copy")
        }
    }
    return (

        <section className="bg-dHome min-vh-100">
            <div className='container text-dark  mx-auto mb-3 overflow-hidden pb-5' style={{ fontWeight: 500, paddingTop: "200px" }}>
                {/* <Button variant="success" className='py-3 px-4'
                    disabled={open}
                >Claim</Button> */}
                <div className='mx-auto d-flex justify-content-center align-items-center flex-column' style={{ gap: "15px" }}>
                    <div className='titleRound rounded-pill' style={{ backgroundColor: "#B3D8F9" }}>
                        <p className='mb-0'>Address : {
                            (!user?.walletAddress || user?.walletAddress === undefined || user?.walletAddress === "undefined") ? "Please login to show your wallet address" : user?.walletAddress
                        }</p>

                        <CopyToClipboard text={`${user?.walletAddress}`} onCopy={copyWalletAddress}>
                            <img src="/assets/copy.png" alt="" className='img-fluid ps-2' style={{ cursor: "pointer" }} />
                        </CopyToClipboard>
                    </div>

                    <div className='titleRound rounded-pill' style={{ backgroundColor: "#FFC2F9" }}>
                        <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title="Stake Minimum 5000 UNITIC To Activate Referral Link">
                            <button className='bg-none border-0 fs-5' style={{ background: "transparent" }}><i class="fas fa-info-circle"></i></button>
                        </Tooltip>
                        {
                            (user?.transactionId === "T0") ?
                                <><p className='mb-0'>Affiliate Link  : Link not activated</p></> :
                                <>

                                    <p className='mb-0'>Affiliate Link  : {user?.myReferralCode.split('com/')[1]}</p>

                                    <CopyToClipboard text={`${user?.myReferralCode}`} onCopy={copyAffiliateAddress}>
                                        <img src="/assets/copy.png" alt="" className='img-fluid ps-2' style={{ cursor: "pointer" }} />
                                    </CopyToClipboard>
                                </>
                        }
                    </div>
                </div>
                <div className='mt-5 container'>
                    <div className='row gy-5'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4  mx-auto'>
                            <div className='cardMaker ms-auto'>
                                <small className='mb-0'>Total Stake: {(data?.length < 10) ? `0${data?.length}` : data?.length}</small>
                                <p className='mb-0 font-bolding'>{sumStake} UNITIC</p>

                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardMaker mx-auto'>
                                <small className='mb-0'>Total Earnings</small>
                                <p className='mb-0 font-bolding'>{totalEarned} UNITIC</p>
                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardMaker me-auto'>
                                <small className='mb-0'>Total Claimed</small>
                                <p className='mb-0 font-bolding'>{claim} UNITIC</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardMaker ms-auto'>
                                <small className='mb-0'>Current Tier: {level?.slice(0, 1)}</small>
                                <p className='mb-0 font-bolding'>{roi}% ROI</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 mx-auto'>
                            <div className='cardMaker me-auto'>
                                <small className='mb-0'>Total Referred</small>
                                <p className='mb-0 font-bolding'>{user?.totalReferred} People</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default DashboardHome;